<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog
        :md-close-on-esc="true"
        :md-active.sync="isVisible"
        @md-clicked-outside="close"
        class="lims-modal large-modal"
      >
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <div class="lims-form dialog-content">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50">
              <lims-field class="textarea-field" :model="formData" :schema="schema" field="date">
                <date-picker
                  :disabled="isViewMode"
                  slot="field"
                  range
                  :clearable="false"
                  v-model="formData.date"
                  format="DD/MM/YYYY"
                  :placeholder="$t('global/placeholder.dateRangePicker')"
                ></date-picker>
              </lims-field>
            </div>
            <div v-if="formData.clinicId" class="md-layout-item md-size-25">
              <lims-field :model="formData" :schema="schema" field="clinicName">
                <md-input :disabled="true" slot="field" v-model="formData.clinicName" type="text"></md-input>
              </lims-field>
            </div>
            <div v-if="formData.clinicId" class="md-layout-item md-size-25">
              <lims-field :model="formData" :schema="schema" field="pathologist">
                <md-input :disabled="true" slot="field" v-model="formData.pathologist" type="text"></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout-item md-size-100">
            <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
              <md-textarea :disabled="isViewMode" slot="field" v-model="formData.comment" maxlength="250"></md-textarea>
            </lims-field>
            <p>{{ $t('component/lims/modals/ModalAddHolidays/txt') }}</p>
          </div>

          <md-table class="custom-tbl" md-card>
            <md-table-row>
              <md-table-head></md-table-head>
              <md-table-head class="th-center">
                <md-checkbox :disabled="isViewMode" v-model="selectAllLims" class="checkbox-all">
                  {{ $t('global/notification/notification.Lims') }}
                  <md-tooltip class="tooltip-selectAll" v-html="$t('global/tooltip/selectAll')"></md-tooltip>
                </md-checkbox>
              </md-table-head>
              <md-table-head class="th-center">
                <md-checkbox :disabled="isViewMode" v-model="selectAllEmail" class="checkbox-all">
                  {{ $t('global/notification/notification.Email') }}
                  <md-tooltip class="tooltip-selectAll" v-html="$t('global/tooltip/selectAll')"></md-tooltip>
                </md-checkbox>
              </md-table-head>
              <md-table-head class="th-center">
                <md-checkbox :disabled="isViewMode" v-model="selectAllSms" class="checkbox-all">
                  {{ $t('global/notification/notification.SMS') }}
                  <md-tooltip class="tooltip-selectAll" v-html="$t('global/tooltip/selectAll')"></md-tooltip>
                </md-checkbox>
              </md-table-head>
            </md-table-row>

            <md-table-row v-for="(item, name) in notifications" :key="name">
              <md-table-cell>{{ item.notificationDescription }}</md-table-cell>
              <md-table-cell class="cell-action">
                <md-checkbox :disabled="isViewMode" v-model="item.isUseInApp" class="lims-checkbox"></md-checkbox>
              </md-table-cell>
              <md-table-cell class="cell-action">
                <md-checkbox
                  :disabled="isViewMode"
                  v-if="item.canUseEmail"
                  v-model="item.isUseEmail"
                  class="lims-checkbox"
                ></md-checkbox>
              </md-table-cell>
              <md-table-cell class="cell-action">
                <md-checkbox
                  :disabled="isViewMode"
                  v-if="item.canUseSMS"
                  v-model="item.isUseSMS"
                  class="lims-checkbox"
                ></md-checkbox>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button v-if="!isViewMode" @click="onSave" :disabled="invalid" class="lims-form-button md-primary">{{
            $t('global/button/button.save')
          }}</md-button>
          <md-button v-if="!isViewMode" @click="onRemove" :disabled="invalid" class="lims-form-button md-danger">{{
            $t('global/button/button.delete')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import modalMixins from './modal.mixins';
import { CalendarService } from '@/services';

export default {
  name: 'ModalEditHolidays',
  mixins: [modalMixins],
  props: {
    notificationTypes: {
      require: true,
    },
  },
  created() {
    this.notifications = cloneDeep(this.notificationTypes);
  },
  data() {
    return {
      formData: {},
      notifications: null,
    };
  },
  watch: {},
  computed: {
    schema() {
      return {
        entity: 'modals/ModalAddHolidays',
        fields: {
          date: 'required',
          comment: '',
          pathologist: '',
          clinicName: '',
        },
      };
    },
    selectAllLims: {
      set(selected) {
        this.notifications.forEach(function (val) {
          val.isUseInApp = selected;
        });
      },
      get() {
        return this.notifications.every(function (val) {
          return val.isUseInApp;
        });
      },
    },
    selectAllEmail: {
      set(selected) {
        this.notifications.forEach(function (val) {
          val.isUseEmail = selected;
        });
      },
      get() {
        return this.notifications.every(function (val) {
          return val.isUseEmail;
        });
      },
    },
    selectAllSms: {
      set(selected) {
        this.notifications.forEach(function (val) {
          val.isUseSMS = selected;
        });
      },
      get() {
        return this.notifications.every(function (val) {
          return val.isUseSMS;
        });
      },
    },
    isViewMode() {
      return this.formData.clinicId ? true : false;
    },
  },

  methods: {
    async setEvent(evt) {
      const event = cloneDeep(evt);
      const eventData = {
        holidayId: event.holidayId,
        date: [new Date(event.startDate), new Date(event.endDate)],
        comment: event.comment,
        clinicName: event.clinicName || '',
        pathologist: `${event.firstname} ${event.lastname}`,
        clinicId: event.clinicId || null,
      };

      let notificationData = [];
      let res;
      if (eventData.clinicId !== null) {
        res = await CalendarService.getHolidayOfClinic({
          holidayId: event.holidayId,
          clinicId: event.clinicId,
        });
      } else {
        res = await CalendarService.getHolidayOfCurrentUser(eventData.holidayId);
      }
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      } else {
        notificationData = res.data.notificationSettings;
      }

      this.$set(this, 'formData', eventData);
      this.$set(this, 'notifications', notificationData);
    },
    getEditData() {
      const dataNotifition = this.notifications.map((item) => {
        return {
          ...item,
          isUseEmail: item.canUseEmail ? item.isUseEmail : false,
          isUseSMS: item.canUseSMS ? item.isUseSMS : false,
        };
      });
      return {
        holidayId: this.formData.holidayId,
        startDate: this.formData.date[0],
        endDate: this.formData.date[1],
        comment: this.formData.comment,
        notificationSettings: dataNotifition,
      };
    },
    onRemove() {
      this.$emit('onRemove', this.getEditData());
      this.close();
    },
    onSave() {
      this.$emit('onSave', this.getEditData());
      this.close();
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          date: '',
          comment: '',
        };
      });
    },
  },
};
</script>

<style lang="scss"></style>
