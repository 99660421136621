<template>
  <div class="p-calendar">
    <modal-add-holidays
      ref="addHolidayModal"
      @onSave="addHoliday"
      :notifications="notificationList"
    ></modal-add-holidays>
    <modal-edit-holidays
      v-if="isReady"
      ref="editHolidayModal"
      @onSave="saveHoliday"
      @onRemove="removeHoliday"
      :notificationTypes="notificationList"
    ></modal-edit-holidays>
    <div class="md-layout">
      <div class="md-layout-item md-size-20 calendar-sidebar">
        <md-button class="lims-form-button" @click="onAddHolidays()">
          {{ $t('page/MyAccount/Calendar/button.addHolidays') }}
        </md-button>
        <collapse
          :wrapperClass="'my-calendar-collapse'"
          :collapse="[$t('page/MyAccount/Calendar/title.myCalendars')]"
          icon="keyboard_arrow_down"
        >
          <template slot="md-collapse-pane-1">
            <ul class="calendar-list">
              <li v-for="calendar in calendars" :key="`${calendar.id}`">
                <md-checkbox v-model="selectedCalendars" :value="calendar.id">{{ calendar.name }}</md-checkbox>
              </li>
            </ul>
          </template>
        </collapse>
        <div></div>
      </div>
      <div class="md-layout-item md-size-80">
        <md-card class="md-card-calendar">
          <md-card-content>
            <fullCalendar ref="calendar" :options="calendarOptions" />
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse } from '@/components';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ModalAddHolidays from '@/components/Lims/modals/ModalAddHolidays.vue';
import ModalEditHolidays from '@/components/Lims/modals/ModalEditHolidays.vue';
import { CalendarService } from '@/services';

export default {
  components: {
    Collapse,
    FullCalendar,
    ModalAddHolidays,
    ModalEditHolidays,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      notificationList: [],
      clinicGroup: [],
      checkboxValue: [],

      // state
      isReady: false,
      calendars: [],
      calendarOptionValues: null,
      initialEvents: [],
      events: [],
      selectedCalendars: [],
    };
  },
  computed: {
    calendarOptions() {
      return {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          //center: 'dayGridMonth,timeGridWeek,timeGridDay',
          right: 'prev,next,today',
        },
        firstDay: 1, // Monday
        initialView: 'dayGridMonth',
        initialEvents: this.initialEvents, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        dayMaxEvents: true,
      };
    },
  },
  watch: {
    selectedCalendars: {
      deep: true,
      handler: function (val) {
        if (this.$refs.calendar) {
          this.$refs.calendar
            .getApi()
            .getEvents()
            .map((event) => {
              event.remove();
            });
          this.initialEvents.map((e) => {
            if (val.map((v) => v.toString()).includes(e.groupId.toString())) {
              this.$refs.calendar.getApi().addEvent(e);
            }
          });
        }
      },
    },
  },
  methods: {
    fetchData() {
      this.loadCalendars();
      this.loadNotificationList();
    },

    async loadCalendars() {
      const { data, error } = await CalendarService.getCalendar();
      if (error) {
        this.$alertErrorServerSide(error);
        return;
      }
      const { calendars, events } = data;
      const selectedCalendars = [];
      calendars.map((c) => {
        selectedCalendars.push(c.id);
      });
      this.initialEvents = events;
      this.calendars = calendars;
      this.selectedCalendars = selectedCalendars;

      this.isReady = true;
    },
    async loadNotificationList() {
      const res = await CalendarService.getNotificationCalendar();
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      }
      this.notificationList = res.data.notificationSettings;
      return this.notificationList;
    },

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    handleEvents(events) {
      this.currentEvents = events;
    },

    // get infor to edit events
    handleEventClick(e) {
      const event = e.event._def.extendedProps;
      this.editEvent(event);
    },

    editEvent(event) {
      this.$refs.editHolidayModal.setEvent(event);
      this.$refs.editHolidayModal.open();
    },

    // select on Calendaer to add holiday
    handleDateSelect(selectInfo) {
      this.dataSelect = selectInfo;
      this.$refs.addHolidayModal.open();
    },

    onAddHolidays() {
      this.$refs.addHolidayModal.open();
    },
    async addHoliday(val) {
      const res = await CalendarService.createHoliday(val);
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      } else {
        this.loadCalendars();
        this.$alertSuccess(this.$t('pages/MyAccount/Calendar/addNewHoliday.success'));
      }
    },
    async saveHoliday(val) {
      const res = await CalendarService.updateHoliday(val);
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      } else {
        this.loadCalendars();
        this.$alertSuccess(this.$t('pages/MyAccount/Calendar/updateHoliday.success'));
      }
    },
    async removeHoliday(val) {
      const res = await CalendarService.removeHoliday(val);
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      } else {
        this.loadCalendars();
        this.$alertSuccess(this.$t('pages/MyAccount/Calendar/removeHoliday.success'));
      }
    },
  },
};
</script>

<style lang="scss"></style>
