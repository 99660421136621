<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog
        :md-close-on-esc="true"
        :md-active.sync="isVisible"
        @md-clicked-outside="close"
        class="lims-modal large-modal"
      >
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <div class="lims-form dialog-content">
          <lims-field class="textarea-field" :model="formData" :schema="schema" field="date">
            <date-picker
              slot="field"
              range
              v-model="formData.date"
              format="DD/MM/YYYY"
              :placeholder="$t('global/placeholder.dateRangePicker')"
            ></date-picker>
          </lims-field>
          <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
            <md-textarea slot="field" v-model="formData.comment"></md-textarea>
          </lims-field>
          <p>{{ $t('component/lims/modals/ModalAddHolidays/txt') }}</p>

          <md-table class="custom-tbl" md-card>
            <md-table-row>
              <md-table-head></md-table-head>
              <md-table-head class="th-center">
                <md-checkbox v-model="selectAllLims" class="checkbox-all">
                  {{ $t('global/notification/notification.Lims') }}
                  <md-tooltip class="tooltip-selectAll" v-html="$t('global/tooltip/selectAll')"></md-tooltip>
                </md-checkbox>
              </md-table-head>
              <md-table-head class="th-center">
                <md-checkbox v-model="selectAllEmail" class="checkbox-all">
                  {{ $t('global/notification/notification.Email') }}
                  <md-tooltip class="tooltip-selectAll" v-html="$t('global/tooltip/selectAll')"></md-tooltip>
                </md-checkbox>
              </md-table-head>
              <md-table-head class="th-center">
                <md-checkbox v-model="selectAllSms" class="checkbox-all">
                  {{ $t('global/notification/notification.SMS') }}
                  <md-tooltip class="tooltip-selectAll" v-html="$t('global/tooltip/selectAll')"></md-tooltip>
                </md-checkbox>
              </md-table-head>
            </md-table-row>

            <md-table-row v-for="(item, name) in notifications" :key="name">
              <md-table-cell>{{ item.notificationDescription }}</md-table-cell>
              <md-table-cell class="cell-action">
                <md-checkbox v-model="item.isUseInApp" class="lims-checkbox"></md-checkbox>
              </md-table-cell>
              <md-table-cell class="cell-action">
                <md-checkbox v-if="item.canUseEmail" v-model="item.isUseEmail" class="lims-checkbox"></md-checkbox>
              </md-table-cell>
              <md-table-cell class="cell-action">
                <md-checkbox v-if="item.canUseSMS" v-model="item.isUseSMS" class="lims-checkbox"></md-checkbox>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onSave" :disabled="invalid" class="lims-form-button md-primary">{{
            $t('global/button/button.save')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';

export default {
  name: 'ModalAddHolidays',
  mixins: [modalMixins],
  props: {
    notifications: {
      default: [],
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  watch: {},
  computed: {
    schema() {
      return {
        entity: 'modals/ModalAddHolidays',
        fields: {
          date: 'required',
          comment: '',
        },
      };
    },
    selectAllLims: {
      set(selected) {
        this.notifications.forEach(function (val) {
          val.isUseInApp = selected;
        });
      },
      get() {
        return this.notifications.every(function (val) {
          return val.isUseInApp;
        });
      },
    },
    selectAllEmail: {
      set(selected) {
        this.notifications.forEach(function (val) {
          val.isUseEmail = selected;
        });
      },
      get() {
        return this.notifications.every(function (val) {
          return val.isUseEmail;
        });
      },
    },
    selectAllSms: {
      set(selected) {
        this.notifications.forEach(function (val) {
          val.isUseSMS = selected;
        });
      },
      get() {
        return this.notifications.every(function (val) {
          return val.isUseSMS;
        });
      },
    },
  },

  methods: {
    onSave() {
      const dataNotifition = this.notifications.map((item) => {
        return {
          ...item,
          isUseEmail: item.canUseEmail ? item.isUseEmail : false,
          isUseSMS: item.canUseSMS ? item.isUseSMS : false,
        };
      });
      const dataAdd = {
        startDate: this.formData.date[0],
        endDate: this.formData.date[1],
        comment: this.formData.comment,
        notificationSettings: dataNotifition,
      };
      this.$emit('onSave', dataAdd);
      this.close();
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          date: '',
          comment: '',
        };
      });
    },
  },
};
</script>

<style lang="scss"></style>
